.tabla{
    border-spacing:2px;
    width:100%;
    margin:0 auto;
    font-size:1.1em;
}
.tabla thead th{
    background-color:#5a5a5a;
    padding:8px;
}
.tabla tbody tr{
    background-color: #2d2d2d;
}
.tabla tbody tr:nth-child(odd){
    background-color:#3e3e3e;
}

.tabla td{
    padding:8px;
}
