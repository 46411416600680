:root{
  --yellow:#fdd10c;
  --green:#8ADE8D;
  --red:#BD4949;
  --blue:#C1CFDA;
  --black:#1C1C1C;
}
html,body, #root{
  height:100%;
  margin:0;
}
body {
  background-color:#1C1C1C;
  box-sizing: border-box;
  font-family:'Lato',sans-serif;
  font-size:12px;
  color:white;
  line-height:1.25em;
  letter-spacing:2px;
  font-variant-numeric: lining-nums;
}
*, *:after, *:before{
  box-sizing: inherit;
}
main{
  padding:75px 15px 0 calc(75px + 0.75em);
  max-width:1350px;
  margin:0 auto;
}
a{
  color:inherit;
  text-decoration: none;
}
.row{
  display:flex;
  flex-wrap:wrap;
  padding:0 15px;
}
