.Button {
  display: flex;
  flex: auto;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 15px;
  border: 1.5px solid #FDD10C;
}

.txtBtn {
  font-weight: bold;
  text-align: center;
  margin: 0;
}
