.inputContainer{
    margin:8px;
    position:relative;
    align-items: center;
    height:fit-content;
    display: grid;
}
.inputContainer.labelInput{
    grid-template-columns: minmax(150px, 2fr) minmax(200px,2fr);
}
.inputContainer .outsideIcon{
    position: absolute;
    right: -15px;
}
.inputContainer>label{
    font-weight: bold;
    margin-right:8px;
    text-transform:uppercase;
    text-align:right;
}
.inputContainer.iconInput{
    grid-template-columns: auto 1fr;
    grid-column-gap:3px;
}
.inputContainer.validated{
    margin:15px 15px calc(.9em + 16px) 15px;
}
.input{
    background-color:#FFFFFF;
    padding:8px;
    border:none;
    font-family: inherit;
    font-size:1.1em;
    height:fit-content;
    width:100%;
}
/*.input.icon{*/
/*    padding-left:45px;*/
/*}*/
.input:focus{
    outline:none;
}
.iconElement{
    color:black;
    width:15px;
    height:100%;
    box-sizing: content-box;
    padding:0 15px;
    background-color:#FFFFFF;
}
.error{
    color:var(--red);
    position:absolute;
    bottom:0;
    right:0;
    font-weight:bold;
    transform:translateY(calc(100% + 4px));
    font-size:.9em;
}


/*--- CHECKBOX Y RADIOS ---*/
.checkContainer{
    display: flex;
    align-items: center;
    position: relative;
    padding-left: calc(1em + 8px);
    /*margin: 0 auto 16px;*/
    cursor: pointer;
    user-select: none;
    line-height:1em;
    width:fit-content;
    justify-content: center;
}
.checkContainer.disabled{
    opacity:.4;
}
.checkContainer>input{
    opacity:0;
    position:absolute;
    cursor:pointer;
    height: 0;
    width: 0;
}
.checkContainer .checkmark{
    position: absolute;
    left: 0;
    height: 1em;
    width: 1em;
    background-color: #FFFFFF;
    border:solid 1px white;
}
.checkContainer .checkmark:after{
    position:absolute;
    content:'';
    width:100%;
    height:100%;
    transition:transform .2s ease-in-out;
    transform: translate(-50%,-50%) scale(0);
    left:50%;
    top:50%;
    transform-origin: center;
}
.checkContainer input:checked ~ .checkmark:after{
    background-color: var(--yellow);
    transform: translate(-50%,-50%) scale(1);
}

.checkContainer .checkmark.rounded, .checkContainer .checkmark.rounded:after{
    border-radius:50%;
}
