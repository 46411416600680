.loaderContainer{
    position:fixed;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.7);
    z-index:1090;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
}
.loaderContainer>img{
    width:80px;
    object-fit: contain;
}
.loaderContainer .loaderBar{
    width:120px;
    border-radius:8px;
    background-color:white;
    height:8px;
    margin-top:30px;
    overflow:hidden;
}

.loaderContainer .loaderBarLoading{
    width:35%;
    background-color: var(--yellow);
    height:100%;
    border-radius:8px;
    animation: loading .7s ease-out infinite;
}
@keyframes loading {
    0%{
        transform:translateX(-100%);
    }
    100%{
        transform: translateX(120px);
    }
}
