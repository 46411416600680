.container{
    height:fit-content;
    display:flex;
    position:relative;
    flex-direction:column;
    box-shadow:0 4px 6px 0 rgba(0,0,0,.2);
    color:#1C1C1C;
    min-width: 60%;
    margin:8px;
}
.input{
    background-color:#FFFFFF;
    padding: 8px;
    border:none;
    font-family: inherit;
    font-size:1.1em;
    height:fit-content;
    width:100%;
}
.inputIcon{
    padding:8px 15px 8px calc(23px + 1em);
}
.input:focus{
    outline:none;
}
.iconElement{
    position:absolute;
    left:8px;
    color:#1C1C1C;
    width:1em;
    top:50%;
    transform: translateY(-50%);
}
.closeElement{
    position:absolute;
    right:8px;
    color:#1C1C1C;
    width:1em;
    top:50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.suggestionsContainer{
    background-color:white;
    position:absolute;
    bottom:0;
    transform: translateY(100%);
    width:100%;
    max-height:150px;
    overflow:auto;
    z-index:99;
    box-shadow:0 3px 6px 0 rgba(0,0,0,.16);
    text-align:left;
}
.focused{
    background-color:#EBEBEB !important;
    color:var(--black) !important;
}
.option{
    cursor:pointer;
    padding:8px 15px;
    overflow:hidden;
    text-overflow:ellipsis;
}
