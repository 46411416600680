#legend-container{
    margin-left: 60px;
}
#legend-container>div{
    display:grid;
    grid-template-columns: 15px 1fr 30px;
    grid-column-gap:8px;
    cursor:pointer;
    margin:8px 0;
    align-items: center;
}
#legend-container>div span{
    font-size:.9em;
}
#legend-container>div:hover span{
    color:#9F9F9F;
}
#legend-container>div:hover .legend-item--circle{
    transform: scale(1.2);
}
#legend-container .legend-item--circle{
    height:15px;
    border:solid 1px white;
    border-radius: 50%;
    transition: transform .15s ease-in-out;
}

.grafica-aberas svg{
    fill:white;
}
.grafica-aberas .c3-tooltip td{
    color:var(--black);
    font-size:.9em;
    text-transform: uppercase;
}

.grafica-aberas svg{
    overflow:visible !important;
}
