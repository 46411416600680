.inputDate{
    position:relative;
    width:fit-content;
}

.dateElement{
    margin:8px 0;
}
.dateElement>span{
    text-decoration:underline;
    margin-left:4px;
}
.datesPicker{
    display:flex;
    align-items:center;
}
.infoDates .dateElement{
    display:grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
}
.datePickerInput{
    padding:8px;
    background-color:white;
    display:flex;
    color:var(--black);
    cursor:default;
    justify-content:space-between;
}
.datePickerInput>svg{
    margin-left:8px;
    height:1.1em;
 }
