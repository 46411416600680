.panel{
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color: #292929;
    padding:15px 60px;
    max-width:700px;
}
.panel>h3{
    text-transform:uppercase;
    font-size:1.2em;
}
.lateralPanel{
    position:absolute;
    left:0;
}
