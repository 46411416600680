.btn{
    border:0;
    text-transform:uppercase;
    font-weight:bold;
    color:#1c1c1c;
    border-radius:24px;
    padding:8px 15px;
    cursor:pointer;
    position:relative;
    overflow:hidden;
    box-shadow:0 3px 6px 0 rgba(0,0,0,.16);
    min-width:150px;
    /*--- PRVISIONAL ---*/
    margin:15px;
}
.justIcon{
    border:0;
    border-radius:24px;
    cursor:pointer;
    position:relative;
    overflow:hidden;
    margin:3px;
}

.justIcon:after{
    content: '';
    width: 200px;
    height: 200px;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(0);
    transition:none;
    border-radius: 50%;
    transform-origin: center;
}
.justIcon:focus{
    outline:none;
}
.justIcon:focus:after{
    animation:clickBtn .3s ease-out;
}

.btn:after{
    content: '';
    width: 200px;
    height: 200px;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(0);
    transition:none;
    border-radius: 50%;
    transform-origin: center;
}
.btn:focus{
    outline:none;
}
.btn:focus:after{
    animation:clickBtn .3s ease-out;
}
.icon{
    border-radius:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height:85px;
    width:135px;
}
.icon>svg{
    width:25px !important;
    min-height:25px;
    margin-bottom:8px;
}

.btn:disabled{
    opacity:.5;
    cursor:default;
}

@keyframes clickBtn {
    from{ transform: translate(-50%,-50%) scale(0)}
    to{ transform: translate(-50%,-50%) scale(1)}
}
