.menu-container{
    position:fixed;
    top:60px;
    display:flex;
    flex-direction: column;
    height:calc(100% - 60px);
    background-color: #4b4b4b;
    z-index:99;
    color:white;
    padding:70px 0;
    transform: translateX(-145px);
    font-family: 'poppins', sans-serif;
}
/*.menu-container:hover{*/
/*    transform: translateX(0);*/
/*}*/

.menu--item{
    display:grid;
    align-items:center;
    grid-template-columns: 120px calc(0.75em + 60px);
    padding:16px 0 16px 25px;
    margin:4px 0;
    font-size:1.5em;
    cursor:pointer;
    position:relative;
}
.menu--item.active:after{
    opacity:1;
    transform:scaleX(0.04);
}
.menu--item:hover:after{
    transform: scaleX(1);
}
.menu--item:after{
    content:'';
    height: 100%;
    width:100%;
    position:absolute;
    top:0;
    right:0;
    transition: transform .3s ease-in-out;
    transform: scaleX(.02);
    transform-origin: right;
    z-index:-1;
    opacity:.4;
}
.menu--item:nth-child(2):after{
    background-color: var(--yellow);
}
.menu--item:nth-child(3):after{
    background-color: var(--green);
}
.menu--item:nth-child(4):after{
    background-color: #B59FDC;
}
.menu--item:nth-child(5):after{
    background-color: var(--blue);
}
.menu--item:nth-child(6):after{
    background-color: #DC9B79;
}
.menu--item:last-child:after{
    background-color: var(--red);
}


.menu--item>span{
    text-align:right;
    justify-self: flex-end;
    font-size:.8em;
}
.menu--item>svg{
    margin:0 30px;
    width:1.2em;
}
