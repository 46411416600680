.container{
    width: 100%;
    padding: 0 30px;
    align-self: center;
    display: flex;
    flex-direction: column;
    max-width:800px;
    margin:0 auto;
}
.selectedGroup{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height:calc(0.65em + 10px);
    overflow:hidden;
    padding:15px 0;
}
.selectedItem{
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    white-space: nowrap;
    font-size:.8em;
    position:relative;
    cursor:pointer;
}
.autocomplete{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.selectedItemTxt{
    color: var(--black);
    margin: 5px 25px;
}
.closeIcon{
    cursor: pointer;
}
.iconItem{
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--yellow);
    border-radius: 50%;
    height: 15px;
    border: solid 1px var(--black);
    width: 15px;
    box-sizing: content-box;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(5px, -5px);
    font-size:.8em;
}
