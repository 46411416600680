.row-container {
  display: flex;
  margin-top: 5px;
}

.fecha-container {
  display: flex;
  width: 25%;
  justify-content: space-around;
  align-items: center;
  padding: 0 3% 0 3%;
}

.base-container {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.iva-container {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.total-container {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.txtGeneric {
  text-align: center;
  font-size: 12;
  font-weight: bold;
}

.ingresosContainer {
  display: flex;
  margin-top: -20px;
}

.selectorContainer {
  margin: 30px 0 0 0;
  display: flex;
  width: 25%;
  flex-direction: row;
  justify-content: center;
}

.selectorLeft {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-width: 1.5px;
  align-items: center;
  justify-content: center;
}

.selectorRight {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-width: 1.5px;
}
