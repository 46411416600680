.login-container{
    height:100%;
    display: flex;
    align-items: center;
}
.login-container form{
    margin:auto;
    text-align:center;
}
.PanelLogin{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}
.PanelInput{
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.IconInput{
    width: 50px;
    background-color: white;
    border: gray 1px solid;
    margin: 4px;
}
.TextInput{
    outline: none;
    width: 250px;
    padding: 8px;
    margin: 4px;
}
