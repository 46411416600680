
    .background {
        display: flex;
        flex: 1;
        align-items: center;
    }
    .screenBackground {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: stretch;
        padding-right: 2%;
        padding-left: 2%;
    }
    .pnlLogin {
        /* width: Math.min(412, screenWidth * 0.68); */
        width: 412px;
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pnlOlvidaste {
        width: 412px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .pnlInput {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 18px;
        margin-bottom: 18px;
        margin-left: 1px;
        margin-right: 1px;
    }
    .txtInput {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 80%;
        height: 100%;
        font-size: 28px;
    }
    .txt {
        padding: 6px;
        width: 84%;
        height: 100%;
        font-size: 28px;
        text-align: left;
    }
    .pnlRecordar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
        margin-left: 1px;
        margin-bottom: 16px;
        margin-right: 1px;
    }
    .pnlIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
    }
    .pnlIconRecordar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16%;
    }
    .btnSubmit {
        margin-top: 25px;
        margin-bottom: 25px;
        border-radius: 40px;
        overflow: hidden;
        padding-right: 55px;
        padding-left: 55px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .txtSubmit {
        align-self: center;
        font-size: 31px;
    }
    .btnOlvidaste {
        border-radius: 40px;
        overflow: hidden;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .leftBanner {
        position: absolute;
        width: 12px;
        height: 60%;
        bottom: 0px;
        left: 0px;
    }
    .txtCopyright {
        font-size: 21px;
        text-align: center;
        margin-top: 35px;
    }