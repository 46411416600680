header{
    height:60px;
    background-color: #4b4b4b;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-left:75px;
    position: fixed;
    width:100%;
    z-index:99;
    padding-right:30px;
}

header>div{
    margin: 0 15px;
}

header .header-dates{
    display:flex;
}
header .header-dates>div{
    margin:0 15px;
    display:flex;
    align-items:center;
}
header .header-dates>div>span{
    color:var(--yellow);
    font-weight:bold;
    margin:0 8px;
}
header .header-dates svg{
    color:var(--yellow);
}

header .header-info{
    display:flex;
    align-items: center;
    font-size:1.3em;
}
header .header-info>div:first-child{
    display:flex;
    align-items:center;
}
header .header-info>div:first-child span{
    font-size:.7em;
    font-weight:300;
    margin-right:8px;
    color:var(--yellow);
}
header .header-info>div:last-child{
    border-left:solid 1px var(--yellow);
    padding:8px 15px;
    margin-left:15px;
}
